<template>
  <div v-if="selectedObjetivo">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <objetivosForm
        btnSubmit="Editar Objetivo"
        processType="update"
        :objetivo="selectedObjetivo"
        @processForm="editar"
      />
    </b-overlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import objetivosForm from './ObjetivosForm.vue'

export default {
  components: {
    objetivosForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('objetivos', ['selectedObjetivo']),
  },
  methods: {
    ...mapActions({ updatePersonalizado: 'objetivos/updatePersonalizado' }),
    editar(objetivo) {
      this.spinner = true
      this.updatePersonalizado(objetivo).then(() => {
        const apiResponse = store.state.objetivos.API_RESPONSE
        if (apiResponse === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Objetivo guardado 👍',
              icon: 'CheckIcon',
              text: 'el objetivo fue guardado con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$router.replace({
            name: 'objetivos',
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: store.state.objetivos.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
